export const items = [
  {
    label: "Personal",
    to: "#",
    submenuClass: "two-column",
    submenu: [
      {
        label: "Build Wealth",
        byline: "Live comfortably",
        icon: "dollar",
        to: "https://join.myxeno.com/?country=KE&goal=WEALTH",
      },
      {
        label: "Fund Emergencies",
        byline: "For that rainy day",
        icon: "umbrella",
        to: "https://join.myxeno.com/?country=KE&goal=EMERGENCIES",
      },
      {
        label: "Child's Education",
        byline: "Invest in their future",
        icon: "graduation",
        to: "https://join.myxeno.com/?country=KE&goal=EDUCATION",
      },
      {
        label: "Own a Home",
        byline: "Save for comfort",
        icon: "home",
        to: "https://join.myxeno.com/?country=KE&goal=HOME",
      },
      {
        label: "Custom",
        byline: "Your goal, your terms",
        icon: "gift",
        to: "https://join.myxeno.com/?country=KE&goal=Custom",
      },
      {
        label: "Secure Retirement",
        byline: "Comfortable future",
        icon: "sun",
        to: "https://join.myxeno.com/?country=KE&goal=RETIREMENT",
      },
      {
        label: "Income Drawdown",
        byline: "Regular cash withdraw",
        icon: "hourglass",
        to: "https://join.myxeno.com/?country=KE&goal=INCOME",
      },

      // {
      //   label: "Hello January Challenge",
      //   byline: "Save for Jan-worry",
      //   icon: "calendar",
      //   to: "https://join.myxeno.com/?country=KE&goal=Hello%20January%20Challenge",
      // },
    ],
  },
  {
    label: "Groups",
    to: "#",
    submenu: [
      {
        label: "SACCO",
        byline: "Invest as a group",
        icon: "network",
        to: "https://join.myxeno.com/?country=KE&group=SACCO",
      },
      {
        label: "Clubs",
        byline: "Invest as a club",
        icon: "group",
        to: "https://join.myxeno.com/?country=KE&group=CLUB",
      },
      {
        label: "Family",
        byline: "Save as family",
        icon: "heart",
        to: "https://join.myxeno.com/?country=KE&group=FAMILY",
      },
    ],
  },
  {
    label: "Institutions",
    to: "#",
    submenu: [
      {
        label: "Corporate",
        byline: "Invest idle cash",
        icon: "building",
        to: "https://join.myxeno.com/?country=KE&group=CORPORATE",
      },
      {
        label: "Retirement Schemes",
        byline: "Invest for staff",
        icon: "umbrella",
        to: "https://join.myxeno.com/?country=KE&group=SCHEME",
      },
    ],
  },
  {
    label: "Company",
    to: "https://invest.myxeno.com/company",
    submenu: [
      {
        label: "Blog",
        byline: "News & Updates",
        icon: "news",
        to: "https://learn.myxeno.com",
      },
      {
        label: "About",
        byline: "Journey & Governance",
        icon: "chevrons",
        to: "https://invest.myxeno.com/company",
      },
      {
        label: "Goal-based Investment",
        byline: "Our Philosophy",
        icon: "goal",
        to: "https://learn.myxeno.com/articles/what-is-goal-based-investing",
      },
      // {
      //   label: "Investor Relations",
      //   byline: "Reports & Performance",
      //   icon: "book",
      //   to: "/investor-relations",
      // },
      {
        label: "Fund Fact Sheets",
        byline: "Fund Asset Composition",
        icon: "chart",
        to: "/fund-fact-sheets",
      },
    ],
  },
  {
    label: "Help",
    to: "#",
    submenu: [
      {
        label: "Common Questions",
        byline: "Get answers",
        icon: "help",
        to: "https://support.myxeno.com/hc/en-us/categories/15568928675869-Common-Questions",
      },
      {
        label: "Investor Toolkit",
        byline: "Master your money",
        icon: "money",
        to: "https://support.myxeno.com/hc/en-us/categories/15566794761117-The-Investor-s-Toolkit",
      },
      {
        label: "Product Guides",
        byline: "How Tos",
        icon: "bulb",
        to: "https://support.myxeno.com/hc/en-us/categories/15827615006749-Guides",
      },
      {
        label: "Talk to Advisor",
        byline: "Schedule a one-on-one",
        icon: "calendar",
        to: "https://meetings.hubspot.com/joshua-asiimwe",
      },
      {
        label: "WhatsApp Chat",
        byline: "Chat with us",
        icon: "chat",
        to: "https://wa.me/254794229922",
      },
    ],
  },
  {
    label: "Login",
    to: "https://invest.myxeno.com/login",
  },
  {
    label: "Join",
    to: "https://join.myxeno.com/?country=KE",
    cta: true,
  },
]
